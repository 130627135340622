table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  border: 1px solid #dddddd;
  overflow: scroll;
  /* width: 100%; */
}

tr {
  text-align: left;
  padding: 8px;
  word-wrap: break-word;
  word-break: keep-all;
  height: 40px;
  border: 0.8px solid gray;
  margin-bottom: 10px;
}

td {
  border: 1px solid #ddd;
  white-space: nowrap;
  padding: 5px;
  /* background-color: khaki; */
}

tr:nth-child(even) {
  background-color: #dbe3e6;
  /* border: 1px solid black; */
}

::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1976d2;
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
} */

.linkDeco {
  text-decoration: none;
}

.textDesign {
  color: #858393;
  letter-spacing: 0.2px;
  /* font-weight: 500; */
}